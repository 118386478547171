<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('leave_absences')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('leave_absences')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :export-excel="checkPermission('leaveofabsence_excelexport')"
                              @exportExcel="downloadExcel">
                <b-row>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.student_program_id"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm"
                                          v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" lg="6">
                        <b-form-group :label="$t('reason')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.reason"
                                                 code="leave_of_absence_reasons"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.status"
                                                 code="leave_of_absence_statuses"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <b-form-group :label="$t('student_group')">
                            <multi-selectbox v-model="datatable.queryParams.filter.student_group"
                                             :options="studentGroupOptions"
                                             :multiple="false" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" md="4" lg="2" class="px-2">
                        <b-form-group :label="$t('semester')" >
                            <div class="d-flex justify-content-center border rounded">
                                <b-form-group :label="$t('spring')" class="mr-3">
                                    <b-form-checkbox
                                        id="checkbox-1"
                                        v-model="datatable.queryParams.filter.spring"
                                        name="checkbox-1"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                                <b-form-group :label="$t('fall')" class="ml-3">
                                    <b-form-checkbox
                                        id="checkbox-2"
                                        v-model="datatable.queryParams.filter.fall"
                                        name="checkbox-2"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       @on-show-detail-button-click="onShowDetailButtonClick($event)"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import LeaveOfAbsenceService from "@/services/LeaveOfAbsenceService";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import qs from 'qs'
import ApprovalStepService from "@/services/ApprovalStepService";
import CourseService from "@/services/CourseService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        AcademicYearsSelectbox,
        MultiSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('leave_absences')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                permission: "leaveofabsence_show",
                                callback: (row) => {
                                    window.open('/leave/absences/' + row.id, '_blank');
                                }
                            },
                            {
                                text: this.$t('do_cancel'),
                                class: 'ri-close-circle-line',
                                permission: "approvalstep_cancel",
                                show: (row) => {
                                    if(row.active_step){
                                        return true;
                                    }
                                    return false;
                                },

                                callback: (row) => {
                                    this.$swal.fire({
                                        text: this.$t('are_you_sure_to_cancel'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('yes'),
                                        cancelButtonText: this.$t('no')
                                    })
                                    .then((result) => {
                                        if (result.isConfirmed) {
                                            ApprovalStepService.cancel(row.active_step.id).then((response)=>{
                                                this.filter();
                                            });
                                        }
                                    })
                                }
                            },

                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_delete",

                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        hidden: false,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        hidden: false,
                    },
                    {
                        label: this.$t('program'),
                        field: 'program_name',
                        hidden: false,
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        hidden: false,
                    },
                    {
                        label: this.$t('reason'),
                        field: 'reason_name',
                        hidden: false,
                    },
                    {
                        label: this.$t('status'),
                        field: 'status_name',
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },

            studentGroupOptions: [
                {
                    text: 'TR',
                    value: 'TR'
                },
                {
                    text: 'YU',
                    value: 'YU'
                }
            ]
        }
    },
    created() {
        this.filterSet()
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                student_number: null,
                name: null,
                surname: null,
                academic_year: null,
                reason: null,
                faculty_code: null,
                program_code: null,
                status: null
            };
        },
        filterClear() {
            this.filterSet()
            this.datatable.queryParams.page = 1;

            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;

            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            this.datatable.queryParams.filter.fall = this.datatable.queryParams.filter.fall == false || this.datatable.queryParams.filter.fall == null ? null : true
            this.datatable.queryParams.filter.spring = this.datatable.queryParams.filter.spring == false || this.datatable.queryParams.filter.spring == null ? null : true

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return LeaveOfAbsenceService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            LeaveOfAbsenceService.downloadAsExcel(config)
                .then(res => this._downloadFile(res, this.$t('leave_absences') + '.xlsx'))
                .catch(err => this.showErrors(err))
        },

        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        LeaveOfAbsenceService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.showErrors(error)
                            });
                    }
                })
        },
    }
};
</script>

